
import { defineComponent, ref } from "vue";
import router from "@/router";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import useAuth from "@/api/auth/useAuth";

export default defineComponent({
  name: "LoginPage",
  setup() {
    // login variables
    const email = ref("");
    const password = ref("");
    // session variable
    const { initializeSession } = useAuth();

    const onLogin = () => {
      Auth.signIn(email.value, password.value)
        .then((data: CognitoUser) => {
          const idToken = data.getSignInUserSession()?.getIdToken();
          const session = initializeSession(idToken);
          console.log("Login", session);
          router.push({ name: "home" });
        })
        .catch(e => {
          console.log(e);
        });
    };

    return { email, password, onLogin };
  }
});
